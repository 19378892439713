import { Injectable } from '@angular/core';
import { StateService } from '../state/state.service';
import { GuideState } from '../models/Guide.model';
import { Observable, map, of } from 'rxjs';
import { AccountFlag } from '../models/Profile.model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class GuideService {
  constructor(private state: StateService, private api: ApiService) {}

  trigger(guide: string) {
    window.dispatchEvent(new CustomEvent('guide:start', { detail: guide }));
  }

  start(guide: string) {
    this.state.set({
      guides: {
        ...this.state.get('guides'),
        [guide]: GuideState.Started,
      },
    });
  }

  complete(guide: string, flag_id?: number) {
    this.state.set({
      guides: {
        ...this.state.get('guides'),
        [guide]: GuideState.Completed,
      },
    });

    if (flag_id) {
      this.api
        .post('user/flags/complete', {
          id: flag_id,
        })
        .subscribe();
    }
  }

  cancel(guide: string) {
    this.state.set({
      guides: {
        ...this.state.get('guides'),
        [guide]: GuideState.Cancelled,
      },
    });
  }

  isCompleted(guide: string): Observable<boolean> {
    return this.state.select('flags').pipe(
      map((flags: AccountFlag[]) => {
        const flag = flags.find((f) => f?.name?.startsWith(guide));
        if (flag?.value) return true;

        const guides = this.state.get('guides');
        return [
          GuideState.Completed,
          GuideState.Cancelled,
          GuideState.Skipped,
        ].includes(guides[guide]);
      })
    );
  }
}
